import logo from "./Logotipo Contruccaribe AC_Sello.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_ojdzqkfq.json"
          background="Transparent"
          speed="1"
          style={{ width: "300px", height: "300px" }}
          direction="1"
          mode="normal"
          loop
          autoplay
        ></lottie-player>
      </header>
    </div>
  );
}

export default App;
